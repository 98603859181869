import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";
import { constructActiveDoc } from "../helpers";

import SimplePage from "../templates/simplePage";
import Post from "../templates/post";
import ServicePage from "../templates/ServicePage";
import repositoryConfigs from "../../config/prismic-previews-provider-config";

import "../styles/pages/fourofour.scss";

const NotFoundPage = ({ data }) => {
  const { prismicSettings: settings } = data;
  const {
    fof_background: bg,
    fof_image_mobile: bgMobile,
    fof_description: description,
    fof_link: link,
    fof_link_label: linkLabel,
    fof_title: title,
  } = settings.data;

  return (
    <Layout settings={settings} activeDocMeta={constructActiveDoc(settings)}>
      <article className="fourofour horizontal-shadow horizontal-shadow--bottom">
        <figure className="fourofour__media overflow-hidden">
          <GatsbyImage
            image={bg.gatsbyImageData}
            alt={bg.alt || title || ""}
            className={
              bgMobile?.gatsbyImageData ? "d-none d-md-block w-100 h-100" : null
            }
          />
          {bgMobile?.gatsbyImageData && (
            <GatsbyImage
              image={bgMobile.gatsbyImageData}
              alt={bgMobile.alt || title || ""}
              className="d-md-none w-100 h-100"
            />
          )}
        </figure>
        <section className="fourofour__content px-3 pt-sm-6">
          <h1>{title}</h1>
          <p className="h3 mb-3">{description}</p>
          <Anchor href={link.url} className="h5 fw-bold">
            {linkLabel}
          </Anchor>
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query notFoundPageQuery($lang: String) {
    prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
  }
`;

export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    ...repositoryConfigs[0],
    componentResolver: componentResolverFromMap({
      simple_page: SimplePage,
      post: Post,
      service_page: ServicePage,
    }),
  },
]);
