import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import { getOrganization, contactPoint, imageObject, defaultImage } from "./seoHelpers";

const ServiceSchema = ({ page, settings }) => {
  const config = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          logoUrl
          siteUrl
        }
      }
    }
  `).site.siteMetadata;

  const { url, data } = page;
  const {
    meta_title: metaTitle,
    title,
    meta_description: metaDescription,
    description,
    meta_image: metaImage,
    image,
    meta_service_type: serviceType,
    meta_audience: audience,
    meta_category: category,
  } = data;

  const {
    site_short_title: name,
    site_title: alternateName,
    address_street: street,
    address_locality: locality,
    address_postal: postal,
    phone,
    phones,
    email,
    emails,
  } = settings.data;

  return (
    <Helmet>
      <script type="application/ld+json">{`{
        "@context": "https://schema.org/",
        "@type": "Service",
        "serviceType": "${serviceType || metaTitle || title}",
        "areaServed": {
          "@type": "AdministrativeArea",
          "name": "GR"
        },
        "audience": {
          "@type": "Audience",
          "name": "${audience}"
        },
        "availableChannel": {
          "@type": "ServiceChannel",
          "availableLanguage": [
            { "@type": "Language", "name": "Greek" },
            { "@type": "Language", "name": "English" }
          ],
          "servicePhone": ${contactPoint(phones)}
        },
        "brand": "${name}",
        "category": "${category}",
        "hoursAvailable": [
          {
            "@type": "OpeningHoursSpecification",
            "opens": "09:00:00", "closes":  "21:00:00",
            "dayOfWeek": "https://schema.org/Monday"
          },
          {
            "@type": "OpeningHoursSpecification",
            "opens": "09:00:00", "closes": "21:00:00",
            "dayOfWeek": "https://schema.org/Tuesday"
          },
          {
            "@type": "OpeningHoursSpecification",
            "opens": "09:00:00", "closes":  "21:00:00",
            "dayOfWeek":  "https://schema.org/Wednesday"
          },
          {
            "@type": "OpeningHoursSpecification",
            "opens": "09:00:00", "closes":  "21:00:00",
            "dayOfWeek": "https://schema.org/Thursday"
          },
          {
            "@type": "OpeningHoursSpecification",
            "opens": "09:00:00", "closes":  "21:00:00",
            "dayOfWeek":  "https://schema.org/Friday"
          },
          {
            "@type": "OpeningHoursSpecification",
            "opens": "09:00:00", "closes": "21:00:00",
            "dayOfWeek": "https://schema.org/Saturday"
          }
        ],
        "logo": ${imageObject(defaultImage)},
        "provider": ${getOrganization({
        name,
        alternateName,
        url: new URL(url, config.siteUrl),
        logo: imageObject(defaultImage),
        contactPoint: contactPoint(phones, emails),
        street,
        locality,
        postal,
        phone,
        email,
      })},
        "slogan": "${description.text}",
        "termsOfService": "https://chargespot.cdn.prismic.io/chargespot/49b8cc8f-0452-4f3b-a8d0-2de6c7d0eb18_20210120-oroi-xrisis-tis-ipiresias-chargespot.pdf",
        "description": "${metaDescription || description.text}",
        "url": "${new URL(url, config.siteUrl)}",
        "image": ${imageObject(metaImage.url ? metaImage : image)}
      }`}</script>
    </Helmet>
  );
};

export default ServiceSchema;
