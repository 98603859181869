import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import { Col, Container, Row } from "react-bootstrap";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import AnchorPrismic from "../components/elements/AnchorPrismic";
import { constructActiveDoc } from "../helpers";

import "../styles/pages/post.scss";

const Post = ({ data }) => {
  if (!data) return null;

  const { prismicSettings: settings, prismicPost: page } = data;
  const firstPublicationDate = page.first_publication_date;

  const {
    title,
    content,
    image,
    publication_date: publicationDate,
  } = page.data;

  return (
    <>
      <Seo page={page} settings={settings} />
      <Layout
        settings={settings}
        activeDocMeta={constructActiveDoc(page)}
        headerClass="position-sticky top-0"
      >
        <article className="single-post mt-3 mt-md-5">
          <Container fluid>
            <Row>
              {image?.gatsbyImageData && (
                <Col xxl={{ span: 4, offset: 2 }}>
                  <figure className="single-post__image mb-5">
                    <GatsbyImage
                      image={image.gatsbyImageData}
                      alt={image.alt || title || ""}
                      loading="eager"
                    />
                  </figure>
                </Col>
              )}
              <Col xxl={4}>
                <header>
                  <p className="text-primary">
                    {publicationDate || firstPublicationDate}
                  </p>
                  <h1 className="h3 text-decoration-underline mb-5">{title}</h1>
                </header>
                <section className="single-post__content">
                  <RichText
                    render={content.richText}
                    serializeHyperlink={AnchorPrismic}
                  />
                </section>
              </Col>
            </Row>
          </Container>
        </article>
      </Layout>
    </>
  );
};

export const query = graphql`
  query postQuery($lang: String, $id: String) {
    prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    prismicPost(lang: { eq: $lang }, id: { eq: $id }) {
      ...prismicPostFragment
    }
  }
`;

export default withPrismicPreview(Post);
