import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { RichText } from "prismic-reactjs";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import { Col, Container, Row } from "react-bootstrap";

import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import AnchorPrismic from "../components/elements/AnchorPrismic";
import { constructActiveDoc } from "../helpers";

import "../styles/pages/simple-page.scss";


const SimplePage = ({ data }) => {
  if (!data) return null;

  const { prismicSettings: settings, prismicSimplePage: page } = data;
  const { title, content, image, image_mobile: imageMobile, code } = page.data;

  useEffect(() => {
    if (code.text === "") return null;

    const container = document.querySelector("[data-code-container]");

    if (!container) return null;
    container.appendChild(
      document.createRange().createContextualFragment(code.text)
    );

    return null;
  }, [code]);

  return (
    <>
      <Seo page={page} settings={settings} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <article className="simple-page">
          <header className="simple-page__header mb-5">
            <figure className="horizontal-shadow horizontal-shadow--bottom">
              {image?.gatsbyImageData && (
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt={image.alt || title || ""}
                  className={
                    imageMobile?.gatsbyImageData ? "d-none d-md-block" : null
                  }
                  loading="eager"
                />
              )}
              {imageMobile?.gatsbyImageData && (
                <GatsbyImage
                  image={imageMobile.gatsbyImageData}
                  alt={imageMobile.alt || title || ""}
                  className="d-md-none"
                  loading="eager"
                />
              )}
            </figure>
            {title && (
              <h1 className="py-7 py-md-10 px-3 text-center" dangerouslySetInnerHTML={{__html: title}}></h1>
            )}
          </header>
          <Container fluid>
            <Row>
              <Col
                xxl={{ span: 10, offset: 1 }}
                className="col-xxxl-8 offset-xxxl-2"
              >
                {content.text !== "" && (
                  <RichText
                    render={content.richText}
                    serializeHyperlink={AnchorPrismic}
                  />
                )}
                {code.text !== "" && <div data-code-container />}
              </Col>
            </Row>
          </Container>
        </article>
      </Layout>
    </>
  );
};

export const query = graphql`
  query simplePageQuery($lang: String, $id: String) {
    prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    prismicSimplePage(lang: { eq: $lang }, id: { eq: $id }) {
      ...prismicSimplePageFragment
    }
  }
`;

export default withPrismicPreview(SimplePage);
